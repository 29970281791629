.App {
  background: url("../components/assets/bgr.png") repeat;
  height: 100vh;
}

.App-Main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}


