/* src/components/Loginform.css */

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--MainBG-color);
}
.login-form h2 {
  color: #ccc;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  color:#ccc;
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  color:#ccc;
  background-color: var(--MainHighlight-color);
  border: 2px solid var(--septenary-color);
  border-radius: 10px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.form-group input:focus {
  outline: none;
  border-color: var(--SCHighlight-color);
}