.buttonPosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.funRouletteMain {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;   
}