[class*=push] {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 0;
  color: white;
  font-size: 1.5em;
  margin: 1em;
  outline: none;
  background-color: #c2290a;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 200ms;
}

.push--flat {
  box-shadow: inset 0 3.125px 0 #da2e0b, inset 0 -3.125px 0 #aa2409, inset 0 0 0 3.125px #b32609, inset 0 0 0 8.3333333333px #c2290a, inset 0 0 0 10px #611405, inset 0 0 0 10.8695652174px black, inset 0 0 0 13.3333333333px rgba(247, 133, 110, 0.7), inset 0 0 0 18.1818181818px #c2290a, inset 0 40px 13.3333333333px #aa2409, inset 0 0 10px 16.6666666667px #911f08, 0 5px 0 rgba(0, 0, 0, 0.3);
}
.push--flat:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 10px;
  display: block;
  width: 80px;
  height: 80px;
  border: 6.6666666667px solid rgba(0, 0, 0, 0.3);
  border-width: 0 0 6.6666666667px;
  border-radius: 50%;
  transition-duration: 200ms;
}
.push--flat:active, .push--flat.is-pushed {
  box-shadow: inset 0 3.125px 0 #da2e0b, inset 0 -3.125px 0 #aa2409, inset 0 0 0 3.125px #b32609, inset 0 0 0 8.3333333333px #c2290a, inset 0 0 0 10px #611405, inset 0 0 0 11.7647058824px black, inset 0 0 0 13.3333333333px rgba(247, 133, 110, 0.2), inset 0 0 0 18.1818181818px #b32609, inset 0 40px 13.3333333333px #9b2108, inset 0 0 10px 16.6666666667px #791a06, 0 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #b8270a;
}
.push--flat:active:after, .push--flat.is-pushed:after {
  bottom: 11.6666666667px;
  border-width: 0;
}