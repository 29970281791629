@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: "Play",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 24px;
  color: #333;
  margin: 0;
}

:root{
  --MainBG-color: #071824;
  --MainHighlight-color: #0c384d;
  --SecondryBG-color: #0F212D;
  --DarkBG-color: #0b0016;
  --SCHighlight-color: #192b33;
  --senary-color: #032327;
  --septenary-color:#074d57;
  --octonary-color: #333;
  --nonary-color: #f0f0f0;
  --denary-color: #333;
}
