.cursor{
    cursor: pointer;
}
.RRSidepannel1{
    width:30%;
    height:100%;
}
.RRname{
    width:100%;
    height:40%; 
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;

}

.RRchipset{
    gap:20px;
    width:100%;
    height:30%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 0 0 20px;
    /* box-shadow:  0px 20px 50px 5px black; */
}
.betbuttonsdiv{
    width:30%;
    height:100%;
    border-radius: 0 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.bettotamount{
    width:100%;
    height:30%;
    border-radius: 20px;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    /* box-shadow:  0px 20px 50px 5px black; */
}

.userAmount{
    width:80%;
    height:100%;
    color: thistle;
    font-weight: bolder;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* background-color: black; */
    /* border-radius: 30px; */
    /* border: 4px solid ivory; */
}


.chipselectordiv :hover{
    cursor: pointer;
    margin-bottom: 1%;
}
.selectedchip{
    cursor: pointer;
    box-shadow: 0 2px 10px 6px goldenrod;
    margin-bottom: 2%;
}
.RRlast10num{
    width: 100%;
    height: 40%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    transform: scaleX(-1);  
    
}
.betbuttonscomm{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.betbbuttonsdiv{
    width: 80%;
    height: 30%;
    display: flex;
    justify-content: space-evenly;
}
.betAmount{
    width:80%;
    height:100%;
    color: thistle;
    font-weight: bolder;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* background-color: black;
    border-radius: 30px;
    border: 4px solid ivory; */
}
.betbuttons{
   height: 40px;
   width: 80px;
    border-radius:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid white;
    color: white;
    font-weight: bolder;
}
.betbuttons:hover{
    cursor: pointer;
    margin-bottom: 1%;
    box-shadow: 0 2px 5px 3px black;
}
.divred{
    background: linear-gradient(to bottom, red, darkred);
}
.divgreen{
    background: linear-gradient(to bottom, green, darkgreen);
}
.betbuttons button{
    width: 100%;
    height: 100%;
    background-color: unset;
    border: none;
    color: white;
    font-weight: bolder;
    font-family: inherit;
}

.golden-base {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight:bold;
    -webkit-margin-before: 0.3em; 
    -webkit-margin-after: 0.2em;
  }
  .golden1 {
    background-image: -webkit-linear-gradient(#FFF65C, #3A2C00);
    text-shadow: -0.02em -0.03em 0.005em rgba(255, 223, 0, 0.60);
  }
  .golden2 {
    background-image: -webkit-linear-gradient(#E8D800, #E0CF00 52%, #A86800 55%, #A86800 );
    text-shadow: -0.02em -0.03em 0.005em rgba(255, 255, 0, 0.56);
  }
  .golden3 {
    background-image: -webkit-linear-gradient(#FFF65C 45%, #9A8000 75%);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.09),
      -1px -1px 0 rgba(255, 220, 0, 0.670);
    font-size:2em;
  }