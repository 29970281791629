.FunRbettingtabledesign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 99%;
    height: 80%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.FunRbettingtabledesign span{
    color: white;
    font-size: 25px;
    font-weight: bold;
}
.TopSide0123FR{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 70%;
}
.Zero00FR{
    width: 8%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    border: 5px solid darkgoldenrod;
    border-right: none;
    /* background-color: darkgreen; */
}
.zerobetter{
    height: 10%;
    width: 100%;
}
.betzeroline{
    height: 5px;
    width : 100%;
    background-color: darkgoldenrod;
}
.splitzeros{
    width:100%;
    height: 45%
}
.Row3FR{
    width: 90%;
    height: 100%;
    /* border-radius: 0 10px 10px 0; */
    border-right: 5px solid darkgoldenrod;
    border-top: 5px solid darkgoldenrod;
    border-bottom: 5px solid darkgoldenrod;
    /* background-color: grey; */
}
.smallrowbetter{
    height: 3%;
    width: 100%;
}
.RowFR{
    width: 100%;
    height: calc(100% / 4);
}
.duoquadrowbetter{
    height: 10%;
    width: 100%;
}
.duoquadbetterside{
    width: calc(50% - 2.5px);
    height: 100%;
}
.duoquadhorizantal{
    width: 100%;
    height: 5px;
    background: darkgoldenrod;
}
.R1{
    /* background-color: red; */
}
.R2{
    /* background-color: black; */
}
.R3{
    height: 27%;
    /* background-color: red; */
}
.TwoForOne{
    width: 10%;
    height: 100%;
    border-radius: 0 10px 10px 0;
    border-right: 5px solid darkgoldenrod;
    border-top: 5px solid darkgoldenrod;
    border-bottom: 5px solid darkgoldenrod;
}
.two21FR{
    width:100%;
    height: calc(98% / 3);
}
.bordermiddle2to1{
    border-top: 5px solid darkgoldenrod;
    border-bottom: 5px solid darkgoldenrod;
}
.duobettervertical{
    height: 100%;
    width: 2%;
}
.singleBetter{
    height: 100%;
    width:  calc(76% / 12);
}
.duoverticalgradientred{
   background-image:  linear-gradient(to right, rgba(255,0,0,0) 0% , rgba(255,0,0,0) 50%, rgb(255, 0, 0) 50% ,rgb(255, 0, 0) 100%);
}
.duoverticalgradientblack{
    background-image:  linear-gradient(to right, rgba(255,0,0,0) 0% , rgba(255,0,0,0) 50%, rgb(0, 0, 0) 50% ,rgb(0, 0, 0) 100%);
}
.duoverticalgradientBR{
    background-image:  linear-gradient(to right, rgb(0, 0, 0) 0% , rgba(0,0,0) 50%, rgb(255, 0, 0) 50% ,rgb(255, 0, 0) 100%);
}
.duoverticalgradientBB{
    background-image:  linear-gradient(to right, rgb(0, 0, 0) 0% , rgba(0,0,0) 50%, rgb(0, 0, 0) 50% ,rgb(0, 0, 0) 100%);
}
.duoverticalgradientRB{
    background-image:  linear-gradient(to right, rgb(255, 0, 0) 0% , rgba(255,0,0) 50%, rgb(0, 0, 0) 50% ,rgb(0, 0, 0) 100%);
}
.duoverticalgradientRR{
    background-image:  linear-gradient(to right, rgb(255, 0, 0) 0% , rgba(255,0,0) 50%, rgb(255, 0, 0) 50% ,rgb(255, 0, 0) 100%);
}



.betDuoLinevertical{
    height: 100%;
    width: 5px;
    background-color: darkgoldenrod;
}
.bottom3FR{ 
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 10%;
    border-radius: 0 0 10px 10px;
    border-bottom: 5px solid darkgoldenrod;
    border-left: 5px solid darkgoldenrod;
    border-right: 5px solid darkgoldenrod;
}
.better12{
    width : calc(100%/3);
    height:100%;
}
.borderlr{
    border-left: 5px solid darkgoldenrod;
    border-right: 5px solid darkgoldenrod;
}
.Bottom6FR{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 10%;
    border-radius: 0 0 10px 10px;
    border-bottom: 5px solid darkgoldenrod;
    border-left: 5px solid darkgoldenrod;
    border-right: 5px solid darkgoldenrod;
    /* background-color: grey */
}
.splitequal{
    height: 100%;
    width : calc(100% / 6);
    border-left: 2.5px solid darkgoldenrod;
    border-right: 2.5px solid darkgoldenrod;
    font-size: x-large;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
}
.CornerS18FR{
    border-left: none;
}
.CornerB18FR{
    border-right: none;
}
.rhom{
    height:40px;
    width: 40px;
    -webkit-transform: rotate(-45deg) skew(20deg, 20deg);
}