.buttonPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.RRmaindiv{
  border-radius: 50px;
    height: 98vh;
    width: 98vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-image: url(./img/RRBG.png)
}
/* game design css */
.gameDesign{
    height: 50vh;
    width: 95%;
}


.bettingTabledesign{
    height: 100%;
    width: 95%;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* box-shadow: 0px 0px 100px 20px black; */
    border-radius: 50px;
    
}


/* betting pad css */
.bettingpadDesign{
    height: 50vh;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--MainHighlight-color); */
    
}