/* BetButtons.css */

.BetButtonsDesignFR {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: 25%;
    gap: 35px;

}

.BetButtons {
    height: 6vh;
    width: 6vh;
    border-radius: 100%;
    font-size: 16px;
    color: #fff;
    background-color: #886400da;
    border: 10px solid lightgrey;
    padding: 20px 20px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: transform 0.1s, box-shadow 0.1s;
}

.BetButtons:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px goldenrod;
}

.BetButtons:active {
    transform: scale(0.9);
    box-shadow: 0 0 5px goldenrod;
}