/* CSS */
.button-82-temp {
  width:100%;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.red340Gradient{
  background: linear-gradient(
    to left,
    hsl(340deg, 100%, 17%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

/* .Gradient {
  
} */

.greenGradient {
  background: linear-gradient(
    to left,
    hsl(120deg, 100%, 17%) 0%,
    hsl(120deg 100% 32%) 8%,
    hsl(120deg 100% 32%) 92%,
    hsl(120deg 100% 16%) 100%
  );
}


.button-82-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}
.red345Front{
  background: hsl(345deg 100% 47%);
}
.greenFront{
  background: hsl(130, 100%, 40%);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    /* padding: 12px 42px; */
  }
}

.button-82-temp:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-temp:hover .button-82-front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-temp:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-temp:hover .button-82-shadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-temp:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-temp:focus:not(:focus-visible) {
  outline: none;
}