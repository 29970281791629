.bettingPad{
    display: flex;
    justify-content: center;
    align-items: center;
    width:40%;
    height:100%;
    background-color: var(--SecondryBG-color);
    border-radius: 40px;
}
.bettingPadInner{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 90%;
    height: 90%;
    background-color: var(--SCHighlight-color) ;
    border-radius: 40px;
}
.bettingPadClose{
    display: flex; 
    flex-direction: column;
    row-gap: 10px;
    margin-top: 50px;
}
.bettingLabels{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    padding-left:3px;
    /* padding-right: 3px; */
    /* background-color: var(--senary-color); */
    /* border-radius: 20px; */
    /* color: var(--nonary-color); */
    /* margin-top: 10px; */
}
.bettingLabels span{
    font-size: medium;
    color: var(--nonary-color);
}
.bettingPadTitle{
    font-size: medium;
    color: var(--nonary-color);
}
.bettingPadTitle > h3{
    margin:0px;
}

.bettingInput{
    width: 100%;
    height: 40px;
    background-color: var(--senary-color);
    border-radius: 5px;
    border: none;
    color: var(--nonary-color);
    font-size: medium;
    text-align: left;
    padding: 0px;
    /* margin-top: 10px; */
    border: var(--MainHighlight-color) 3px solid;
}
.bettingInput::placeholder{
    padding-left: 5%;
}


.bettingPadButtons{
    /* padding-bottom: 15px; */
    width : 100%;
    display: flex  ;
    justify-content: space-around;
    align-items: center;
    gap:5px;
    
}
.bettingPadButton{
    padding-top: 5px; 
    padding-bottom: 5px;
    width: 35%;
    background-color: var(--senary-color);
    border-radius: 15px;
    border: var(--MainHighlight-color) 3px solid;
    color: var(--nonary-color);
    font-size: 18px;
    /* margin-top: 10px; */
}
.bettingPadButton:hover{
    background-color: var(--MainHighlight-color);
    border: var(--MainBG-color) 3px solid;
}




