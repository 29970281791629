.centercircle {
  position: absolute;
  background: red;
  border-radius: 50%;
  border: thick dashed white;
  box-shadow: 0px 0px 5px 6px black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 1) 0px 3px 6px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
