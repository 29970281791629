.gamePad{
    display: flex;
    justify-content: center;
    align-items: center;
    width:60%;
    height: 100%;
    background-color: var(--DarkBG-color);
    border-radius: 40px;
}
.gamePadInner{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width:95%;
    height:95%;
    /* background-color: var(--SCHighlight-color); */
    
    
}
.squareRows{
    display: flex;
    flex-direction: column;
    gap: 10px;
}