.TokenDisplayFR{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width:20%;
    height:100%;
}
.TokenDivsFR{
    background-color: black;
    color:goldenrod;
    border-radius: 50px;
    border: 5px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width:40%;
    height: 40%;
}
