.squareItem{
    width: 100px;
    height: 100px;
    background-color: var(--MainBG-color);
    border-radius: 10%;
    box-shadow: #0e3148 0px 5px ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.squareItem:hover{
    transform: scale(1.01) translateX(-2px);
    background-color: var(--MainHighlight-color)
    ;
}
.squareItemimg{
    width: 50%;
    height: 50%;
    object-fit: cover;
    opacity: 0.7;
}

.squareClickedimg{
    width: 90%;
    height: 90%;
    object-fit: cover;
    
}