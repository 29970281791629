.spinwheeldesign{
    margin-top: -30px;
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    border-radius: 50%;
    position: relative;
    box-shadow:  0px 0px 50px 20px black;
}
.spincenter{
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: white solid 4px;
    background-position: center;
    background-size: cover
}
.spincenter2{
    height: 230px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: white solid 4px;
}
.goldenborder{
    position: absolute;
    left: 0;
    top: 0;
    width: 280px;
    height: 280px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    border: 10px solid #F3c620;
}
.spinwheel{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 94%;
    width: 94%;
    border-radius: 100%;
}
.spinwheelnum{
    position: absolute;
    top: 1%;
    /* left: 43%; */
    /* transform: translate(-50%, -50%); */
    font-size: 15px;
    color: white;
    transform: rotateZ(-4deg);
}
.ball{
    position: absolute;
    top: 10%;
    left: 50%;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
    z-index: 1;
}
button{
    z-index: 11;
  }
  .target-number-container{
    z-index: 11;
  }