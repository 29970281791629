.SWlableandbet{
    margin-top: 80px;
    width:70%;
    height:65%;
    /* gap:20px; */
    flex-direction: column;
    border-radius: 20px;
    border: 5px solid rgb(174,124,66);
}

.SWchipsetdiv{
    width: 80%;
    height: 45%;
    display: flex;
    justify-content: space-around;
}
.SWbetdetails{
    width:100%;
    height:30%;
}
.flex-even{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.SWbetdetails span{
    color: whitesmoke;
}

.SWFcol{
    flex-direction: column;
}
.SWLables{
    color: whitesmoke;
    padding-top: 8%;
    text-align: center;
    width: 100%;
    height: 80%;
    background-position:center;
    background-repeat: no-repeat;
    background-size:contain
}