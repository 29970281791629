.spinwheelmain{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #050505;
}
.SWBettingPad{
    width: 38% ;
    height: 98% ;
    flex-direction: column;
    background-color: rgb(4, 53, 53);
    border-radius: 50px 0 0 50px;
    
}
.SWGamingPad{
    width: 58% ;
    height: 98% ;
    background: linear-gradient(to right, rgb(4, 53, 53) 0 5%, rgb(4, 53, 26) 40% 100%) ;
    border-radius: 0 50px 50px 0;
   
}
.SpinWheelName{
    position: absolute;
    left: 38%;
    top:0%;
    width: 40vw;
    height: 10vh;
    background: rgb(63,251,211);
    background: radial-gradient(circle, rgba(63,251,211,1) 0%, rgba(156,82,180,1) 27%, rgba(184,78,141,1) 53%, rgba(237,210,71,1) 76%, rgba(244,222,71,1) 84%, rgba(252,70,107,1) 97%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 10;
}
.SpinWheelName span{
    /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    font-size: 3.5em;
    font-weight: 900;
}

.SWSquareBg{
    width: 95%;
    height: 95%;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}