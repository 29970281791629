.DiamondMinerMain{
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--septenary-color);
    
}

.buttonposition{
    top: 2%;
    left: 0.5%;
    position: fixed;
}