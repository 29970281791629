:root {
  --gold: #ffb338;
  --light-shadow: #77571d;
  --dark-shadow: #3e2904;
}

.Game_Cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 80px;
    row-gap: 50px;
    max-width: 80vw;
  }
  .Game_Cards_Single{
    align-content: end;
    height: 240px;
    width: 180px;
    border-radius: 20px;
    border:  rgba(240, 172, 46, 0.774) 5px solid;
  }

  
  .Game_Cards_Single:hover{
    transform: scale(1.1);
    transition: transform 0.3s;
  }
  

 