.bettingTabledesign span{
    color: white;
    font-size: 18px;
    font-weight: bold;
}
.flex-set{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainTable{
    width:900px;
    height: 200px;
    display: flex;
}
.betTable{
    width:800px;
    height:100%;
    flex-direction: column;
}
.zeroAlign{
    width:80px;
    height:100%;
    border: 2px white solid;
    border-radius: 50% 0 0 50%;
    background-color: green;
}
.singleBetLabel{
    width: 800px;
    height:calc(270px / 8);
    display: flex;
    margin-left: 42px;
}
.singlebetvisible{
    width:90%;
    height:100%;
    display:flex;
    margin-left: 12px;
}
.upto18{
    width: 50%;
    height:100%;
    background-color: green;
    border: 2px white solid;
}
.upto36{
    width:50%;
    height:100%;
    background-color: green;
    border:2px white solid;
}
.odd{
    width:25%;
    height:100%;
    border: 2px white solid;
    background-color: green;
}

.even{
    width:25%;
    height:100%;
    border: 2px white solid;
    background-color: green;
}

.red{
    width:25%;
    height:100%;
    color: white;
    background-color: red;
    border: 2px white solid;
}
.black{
    width:25%;
    height:100%;
    color: white;
    background-color: black;
    border: 2px white solid;
}
.first12{
    width:33.33%;
    height:100%;
    background-color: green;
    border: 2px white solid;
}
.second12{
    width:33.33%;
    height:100%;
    background-color: green;
    border: 2px white solid;
}
.third12{
    width:33.33%;
    height:100%;
    background-color: green;
    border: 2px white solid;
}
.remaining{
    width:calc((523px / 13.5)*1.4);
    height:100%;
}
.row1{
    width:100%;
    height:32%;
    background-color: green;
    display: flex;
    border-top: 3px solid white;

}
.row2{
    width:100%;
    height:32%;
    background-color: green;
    display: flex;
}
.row3{
    width:100%;
    height:32%;
    background-color: green;
    display: flex;
}
.rowbet3{
    width:calc(99.5% - (1.5 *(100% /13.5)));
    height:1.34%;
    background-color: white;
    align-self: flex-start;
}
.rowbet1{
    width:calc(99.5% - (1.5 *(100% /13.5)));
    height:1.33%;
    background-color: white;
    align-self: flex-start;   
    
}
.rowbet2{
    width:calc(99.5% - (1.5 *(100% /13.5)));
    height:1.33%;
    background-color: white;
    align-self: flex-start;   
}

.cellT1{
    height: 100%;
    width :calc(90% / 12);  
    color : black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topwhiteborder{
    border-top: 3px white solid;
}
.cellT2{
    height: 100%;
    width :calc(10% / 12);  
    background-color: white;
}
.verticalcellT1{
    width:calc(100%/23);
    height: 100%;
}
.verticalcellT2{
    width:calc(100% /23);
    height: 100%;
}
.rowbetfull{
    height: 100%;
    width: calc(97% /9);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px white solid;
}
.colorRed{
    background-color: red;
}
.colorBlack{
    background-color: black;
}

.disableclick{
    pointer-events: none;
}